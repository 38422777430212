import React, { useState, useRef } from "react";
import {
  Typography,
  Box,
  Container,
  Card,
  CardContent,
  CardMedia,
  useMediaQuery,
  Theme,
  useTheme,
} from "@mui/material";
import "../fontRubik.css";

export const Team: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth="md" style={{ padding: "40px 10px", direction: "rtl" }}>
      <Typography
        variant={isSmallScreen ? "h5" : "h4"}
        component="div"
        align="center"
        sx={{
          flexGrow: 1,
          fontWeight: "bold",
          marginBottom: 3,
        }}
      >
        הכירו את הצוות שלנו
      </Typography>
      <Card
        sx={{
          maxWidth: 800,
          margin: "20px auto",
          borderRadius: "16px",
          overflow: "hidden",
          boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
          padding: "20px", // Add some padding to create space around the image
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardMedia
            component="img"
            image="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/hadas_pic.jpg"
            alt="Dr. Hadas Grossman"
            sx={{
              width: { xs: 150, sm: 200, md: 300 }, // Smaller width for small screens, larger for medium and up
              height: { xs: 150, sm: 200, md: 300 }, // Matching height for circular image
              borderRadius: "50%", // Circular image
              objectFit: "cover", // Ensures the image covers the circle properly
              objectPosition: "top", // Focuses on the top part of the image
            }}
          />
        </Box>
        <CardContent sx={{ padding: "15px", textAlign: "center" }}>
          <Typography
            gutterBottom
            variant={isSmallScreen ? "h5" : "h4"}
            component="div"
            align="center"
            sx={{ fontWeight: "bold" }}
            color={"#e55d8d"}
          >
            ד"ר הדס גרוסמן
          </Typography>
          <Typography
            gutterBottom
            variant={isSmallScreen ? "h5" : "h4"}
            component="div"
            align="center"
            sx={{ paddingBottom: "12px", fontSize: "26px" }}
            color={"#123c42"}
          >
            חוקרת רפואית המתמחה בפוריות
          </Typography>
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            align="right"
            sx={{
              paddingBottom: "10px", // Add some padding to create space around the image
              fontSize: "20px",
            }}
          >
            נעים להכיר, אני חוקרת רפואית והתשוקה הגדולה שלי היא להצליח לפצח
            מקרים מורכבים של בעיות פוריות ולהתאים לכל אישה את הטיפול המדויק
            עבורה. אני מתמחה בהפרעות ביוץ, בדרכים לשיפור איכות הביציות, בחוסר
            איזון הורמונלי על סיבותיו, בהפלות חוזרות ובכשלונות השרשה חוזרים. 8
            שנים של עבודה במעבדה על ביציות, זקיקים, תאי גרנולוזה (התאים העוטפים
            את הביצית בשחלות), במקביל לעבודה עם חיות מודל למחלות ותסמונות של
            מערכת המין הנקבית (אנדומטריוזיס, תסמונת השחלות הפוליציסטיות וגירוי
            יתר שחלתי) לימדו אותי לבחון כל בעיה הן ברמה המולקולרית והן ברמה
            המערכתית.
          </Typography>
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            align="right"
            sx={{
              paddingBottom: "7px", // Add some padding to create space around the image
              fontSize: "20px",
            }}
          >
            זה בדיוק מה שמאפשר לי היום להבין תהליכים המתרחשים בתא ובביצית ברמה
            המולקולרית תוך הסתכלות הוליסטית על הגוף כולו.
          </Typography>
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            align="right"
            sx={{
              paddingBottom: "10px", // Add some padding to create space around the image
              fontSize: "20px",
            }}
          >
            הרצון להיות אמא הוא כל כך בסיסי וטבעי ואין דבר מתסכל יותר, שאת לא
            מצליחה לממש את הרצון הזה. זו תקופה כל כך קשה ואינטנסיבית של התעסקות
            עם רופאים, תרופות, מזרקים ובדיקות אינסופיות. אני יודעת, גם אני בעצמי
            עברתי טיפולי פוריות, והיום אני אמא לשלושה ילדים מתוקים.
          </Typography>

          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            align="right"
            sx={{
              paddingBottom: "7px", // Add some padding to create space around the image
              fontSize: "20px",
            }}
          >
            בשנים האחרונות, אני משלבת את הידע שלי, האהבה העצומה שלי לעולם המחקר,
            שתמיד ממשיך להתעדכן, עם משמעות אדירה - והיא לעזור לנשים המתקשות
            להרות להגשים את חלומן להיות אמא{" "}
          </Typography>
        </CardContent>
      </Card>
      <Card
        sx={{
          maxWidth: 800,
          margin: "20px auto",
          borderRadius: "16px",
          overflow: "hidden",
          boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
          padding: "20px", // Add some padding to create space around the image
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardMedia
            component="img"
            image="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/kappi.png"
            alt="Tamar Hershenbaum"
            sx={{
              width: { xs: 150, sm: 200, md: 300 }, // Smaller width for small screens, larger for medium and up
              height: { xs: 150, sm: 200, md: 300 }, // Matching height for circular image
              borderRadius: "50%", // Circular image
              objectFit: "cover", // Ensures the image covers the circle properly
              objectPosition: "top", // Focuses on the top part of the image
            }}
          />
        </Box>
        <CardContent sx={{ padding: "15px", textAlign: "center" }}>
          <Typography
            gutterBottom
            variant={isSmallScreen ? "h5" : "h4"}
            component="div"
            align="center"
            sx={{ fontWeight: "bold" }}
            color={"#e55d8d"}
          >
            ענת ברמן
          </Typography>
          <Typography
            gutterBottom
            variant={isSmallScreen ? "h5" : "h4"}
            component="div"
            align="center"
            sx={{ paddingBottom: "12px", fontSize: "26px" }}
            color={"#123c42"}
          >
            יועצת לנשים
          </Typography>
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            align="right"
            sx={{
              paddingBottom: "10px", // Add some padding to create space around the image
              fontSize: "20px",
            }}
          >
            אני ענת, אמא לשתי בנות מקסימות ויודעת כמה הרצון לאמהות הוא חזק מכל
            דבר אחר.
          </Typography>
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            align="right"
            sx={{
              paddingBottom: "10px", // Add some padding to create space around the image
              fontSize: "20px",
            }}
          >
            מעל עשור שעבדתי כרכזת כוח אדם. לקראת גיל 40 החלטתי ללכת אחרי הלב שלי
            ויצאתי ללימודי תואר שני בפסיכולוגיה. התמלאתי בהשראה כשלוויתי חברות
            טובות שהתמודדו עם קשיים במסע שלהן לאמהות והבנתי שהתשוקה שלי היא לקחת
            חלק בשינוי אמיתי ולעזור לנשים להגשים את החלום שלהן, להיות אמא.
          </Typography>

          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            align="right"
            sx={{
              paddingBottom: "10px", // Add some padding to create space around the image
              fontSize: "20px",
            }}
          >
            אני מביאה שילוב ייחודי של יכולות טכניות ממוקדות-מטרה בסטנדרטים בלתי
            מתפשרים עם תובנות רבות על המשמעות של מצב רגשי והשפעותיו על הפוריות
            בפרט והבריאות בכלל. אני מחוייבת לוודא שכל אישה שמצטרפת אלינו, יודעת
            ומרגישה שהיא נמצאת במקום תומך, מכיל שעומד ללוות אותה לתקופה ארוכה.
            כי כולנו פה אחת למען השניה
          </Typography>
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            align="right"
            sx={{
              paddingBottom: "7px", // Add some padding to create space around the image
              fontSize: "20px",
            }}
          >
            אני פה במטרה אחת- והיא לעזור לך להכנס להריון, לצלוח אותו ולהפוך
            לאמא. ברגע שאת נכנסת אלינו למרכז, אנחנו הופכות להיות שותפות מלאות
            למסע. נעבור אותו ביחד ונהפוך כל אבן עד שזה יקרה.
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};
