import React from "react";
import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import { FactGoodHands } from "./FactGoodHands";

const cardsData = [
  {
    title: "בעלת דוקטורט במדעי הרפואה",
    baseText: "בהצטיינות יתרה מטעם אוניברסיטת תל אביב. ",
  },
  {
    title: "הובילה מחקר פוריות",
    baseText: "בשיתוף פעולה עם מכון וייצמן שזכה לשלוש מלגות ופרסים.",
  },
  {
    title: "מצטיינת דיקן",
    baseText: "פעמיים.",
  },
  {
    title: "עברה בעצמה טיפולי פוריות",
    baseText: "היום אמא ל- 3 ילדים.",
  },
  {
    title: "מומחית לפתירת בעיות פוריות",
    baseText:
      "בחמש שנים האחרונות סייעה לעשרות זוגות לפתור בעיות פוריות ארוכות טווח.",
  },
  {
    title: "עבדה כיועצת מדעית בכירה ",
    baseText: "באחת מהחברות הגדולות בארץ בתחום המכשור הרפואי והדיאגנוסטיקה. ",
  },
];

export const GoodHands: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        justifyContent="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {" "}
        {/* <Container maxWidth="md" style={{ direction: "rtl", marginTop: 30 }}> */}
        <Typography
          variant={isSmallScreen ? "h5" : "h4"}
          align="center"
          sx={{
            marginBottom: 1,
            color: "black",
            fontWeight: "bold",
            marginTop: 5,
          }}
        >
          איך תדעי שאת בידיים טובות ?
        </Typography>
        <Typography
          variant={isSmallScreen ? "h5" : "h4"}
          align="center"
          sx={{
            marginBottom: 1,
            color: "black",
            // fontWeight: "bold",
            marginTop: 3,
          }}
        >
          עובדות על ד"ר גרוסמן
        </Typography>
        <img
          src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/logo6.png"
          alt="Diagram"
          style={{
            maxWidth: "100%",
            height: "auto",
            marginTop: "40px",
          }}
        />
        {cardsData.map((card) => {
          return <FactGoodHands title={card.title} base={card.baseText} />;
        })}
      </Box>
      {/* </Container> */}
    </>
  );
};
