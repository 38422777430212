import React from "react";

export const WhatsAppButton = () => {
  const phoneNumber = "972508817478"; // Replace with your WhatsApp number
  const message =
    "שלום, הייתי שמחה לקבל פרטים על התמיכה שלכם, על מנת שאוכל בשעה טובה להשלים הריון מוצלח"; // Default message when a user clicks

  return (
    <a
      href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        backgroundColor: "#25D366",
        color: "white",
        borderRadius: "50%",
        width: "60px",
        height: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "30px",
        textDecoration: "none",
        boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
      }}
    >
      <i className="fab fa-whatsapp"></i>
    </a>
  );
};
