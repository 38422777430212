import React from "react";
import { Typography, Card, useMediaQuery, useTheme } from "@mui/material";

export const CardWhy: React.FC<{ title: string; base: string }> = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      sx={{
        maxWidth: 800,
        margin: "20px auto",
        borderRadius: "16px",
        overflow: "hidden",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
        padding: "20px",
        backgroundColor: "#f3e3eb",
        display: "flex",
        alignItems: "center",
        gap: "20px", // Space between image and text
        flexDirection: { md: "row" }, // Stack vertically on small screens
      }}
    >
      <img
        src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/logo_circle.png"
        alt="Diagram"
        style={{
          maxWidth: isSmallScreen ? "60px" : "100px", // Control the image size here
          height: "auto",
        }}
      />
      <div style={{ textAlign: "right" }}>
        <Typography
          variant={isSmallScreen ? "h6" : "h5"}
          align="right"
          sx={{ marginBottom: 1, fontWeight: "bold" }}
        >
          {props.title}
        </Typography>
        <Typography
          variant="h6"
          align="right"
          sx={{
            marginBottom: 1,
            fontSize: { xs: "0.75rem", sm: "0.875rem", md: "1rem" },
          }}
        >
          {props.base}
        </Typography>
      </div>
    </Card>
  );
};
