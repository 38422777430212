import React, { useState, useRef } from "react";
import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import { ItemInclude } from "./ItemInclude";

const cardsData = [
  {
    number: "01",
    img: "https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/cir1.png",
    title: "תובנות חדשות לגבי הקושי שלך להרות",
    baseText:
      "תקבלי הסבר מפורט על הגורמים הפוטנציאליים לאי-פיריון שלך. הכל מבוסס על הבדיקות הרפואיות שלך ונתמך במאמרים העדכניים ביותר. ",
  },
  {
    number: "02",
    img: "https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/cir2.png",
    title: "הכוונה להמשך בירור וטיפול",
    baseText:
      "המלצה על בדיקות נוספות שכדאי ונכון לעשות. כל בדיקה תהיה מלווה בהסבר מדעי התומך בצורך בבדיקה וכולל רפרנסים ייעודים אותם תוכלי להציג לרופא במידת הצורך, אכווין אותך לרופאים מקצועיים ומוחים רלוונטים בתחום",
  },
  {
    number: "03",
    img: "https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/cir3.png",
    title: "מתן מינון מדויק של תוספי תזונה",
    baseText:
      "תוספי תזונה זה עולם רווי ופרוץ. נטילה לא מדויקת במקרה הטוב, לא תעזור. במקרה הרע- עלולה להזיק. בסקירה תקבלי המלצות מדוייקות לגבי תוספי תזונה על סמך הבדיקות הרפואיות שלך. כל המלצה מגובה במחקרים בהם תוכלי לעיין.",
  },
  {
    number: "04",
    img: "https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/cir4.png",
    title: "פתרונות מחוץ לקופסא ודגשים לקראת כניסה להריון",
    baseText:
      "תוך התייחסות לטיפולים פורצי דרך ולא קונבנציונליים, תקבלי המלצות על פרוטוקול טיפול המפרט את היתרונות והחסרונות של שילובי תרופות, הוספת LH, שיטות לסנכרון זקיקים, החזרת עובר טרי למול הקפאת כל העוברים ופרוטוקולים שונים לתמיכה לקראת החזרה.",
  },
];

export const WhatInclude: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Box
        justifyContent="center"
        display="flex"
        flexDirection={"row"} // Stacks vertically on small screens, horizontal on larger
        alignItems="flex-end"
        sx={{ marginTop: 3 }}
      >
        <img
          src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/logo.png"
          alt="Diagram"
          style={{
            maxWidth: "150px",
            height: "auto",
          }}
        />
        {/* Title on the left */}
        <Typography
          variant={isSmallScreen ? "h5" : "h4"}
          //   align="left" // Align text to the left
          sx={{
            marginBottom: 1,
            color: "black",
            fontWeight: "bold",
            marginTop: 3,
            marginRight: { md: "20px", xs: 0 }, // Add margin only on larger screens
            paddingBottom: 1,
          }}
        >
          מה כוללת הסקירה הרפואית?
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        {cardsData.map((card) => (
          <ItemInclude
            key={card.title}
            title={card.title}
            base={card.baseText}
            img={card.img}
            num={card.number}
          />
        ))}
      </Box>{" "}
      {/* </Container> */}
    </>
  );
};
