import React from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";

export const FactGoodHands: React.FC<{ title: string; base: string }> = (
  props
) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Typography
        variant={isSmallScreen ? "h5" : "h4"}
        align="center"
        sx={{
          marginBottom: 1,
          color: "#123c42",
          fontWeight: "bold",
          marginTop: 5,
        }}
      >
        {props.title}
      </Typography>
      <Typography
        variant="h6"
        align="center"
        sx={{
          marginBottom: 1,
          color: "#123c42",
          fontWeight: "bold",
          marginTop: 3,
        }}
      >
        {props.base}
      </Typography>
      <img
        src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/logo5.png"
        alt="Diagram"
        style={{
          maxWidth: "100%",
          height: "auto",
        }}
      />
    </>
  );
};
